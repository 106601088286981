
import Vue from "vue";
import rules from "@/services/helpers/validation-rules";
import FileUploader from "@/components/form-components/FileUploader.vue";

export default Vue.extend({
  name: "CreateGuarantee",

  components: { FileUploader },

  props: {
    newGuaranteeDialog: {
      type: Boolean,
      default: false,
      required: true
    },
    order: {
      type: Object,
      default: () => ({}),
      required: true
    }
  },

  data: () => ({
    rules,
    model: { sum: 0 } as any,
    errorMessages: {} as any,
    loading: false as boolean,
    types: ["bank_deposit", "guaranty_letter"] as Array<any>,
    beneficiaryGuarantees: [] as Array<any>
  }),

  computed: {
    showExistingGuarantees(): boolean {
      return this.model.type === "existing_guarantee";
    },
    showGuaranteeLetter(): boolean {
      return this.model.type === "guaranty_letter";
    }
  },

  async mounted(): Promise<void> {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        this.types = await this.$API.guarantees().getPaymentTypes();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    toggleDialog() {
      this.$emit("toggle");
    },
    async addGuarantee() {
      this.isLoadingGuarantee = true;
      try {
        if ((this.$refs.form as Vue).validate()) {
          // if (this.showExistingGuarantees) {
          //   await this.$API
          //     .guarantees()
          //     .addExistingGuaranteeToOrder(this.model.guarantee_id, {
          //       order_id: this.$route.params.id
          //     });
          // } else {
            const model = { ...this.model };
            model.order_id = this.$route.params.id;
            model.client_id = this.order.client.id;
            model.status = "confirmed";

            await this.$API.guarantees().create(model);
          // }

          await this.getOrderGuarantees();

          this.model = {
            sum: 0
          };
          this.$emit("update");
          this.$emit("toggle");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 3000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.isLoadingGuarantee = false;
    },
    async getBeneficiaryGuarantees(): Promise<void> {
      try {
        if (this.showExistingGuarantees) {
          this.beneficiaryGuarantees = await this.$API
            .guarantees()
            .getBeneficiaryGuarantees(this.order.client.id);

          this.model.proof_document = null;
          this.model.sum = 0;
        } else {
          if (this.model.guarantee_id) {
            this.model.sum = 0;
            this.model.proof_document = null;
            delete this.model.guarantee_id;
          }
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
    },
    setExistingGuarantee(guarantee: any) {
      this.model.sum = guarantee.sum;
      this.model.proof_document = guarantee.proof_document;
      this.model.guarantee_id = guarantee.id;
      this.$forceUpdate();
    },
    closeGuarantee() {
      this.$emit("toggle");
    },
    async getOrderGuarantees(): Promise<void> {
      this.isLoadingGuarantee = true;
      try {
        if (this.showGuarantee) {
          this.guaranteeLog = await this.$API
            .guarantees()
            .getOrderGuarantees(Number(this.$route.params.id));
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message || e);
      }
      this.isLoadingGuarantee = false;
    }
  }
});
